import * as React from 'react'
import { Link } from 'gatsby'
import { TitleHeader } from "components/pages/title-header";
import { Footer } from "components/footer";
import { Page } from "components/layout/sub/page";
import 'styles/design.css';
import { TutorCard } from 'components/tutor-card';
import { CourseCard } from 'components/course-card';
import * as cls from './index.module.css';
import * as clsCmn from 'components/common.module.css';
import * as cn from 'classnames';
import { style } from 'utils/style';
import { Contacts } from '../components/contacts';
import { Content } from '../components/layout/sub/content';
import { graphql } from 'gatsby';

// static urls
import classUrl from 'images/classroom/class-lamps.jpg';


// === === === === === === === === === === === === === === === === === === ===
// === Styled Components

const Cards = style('div', clsCmn.cards);
const Course = style(CourseCard, cn(clsCmn.card, cls.course));
const Tutor = style(TutorCard, cn(clsCmn.card, cls.tutor));


// === === === === === === === === === === === === === === === === === === ===
// === Markup

export default ({data}) => {

  return (
    <Page header='hero'>

      <Content className={clsCmn.sectionBack2}>
        <h2 id="courses">Курсы</h2>
        <Cards>
          {data.allCourseCardsJson.nodes.map((i, idx) => <Course key={idx} {...i} />)}
        </Cards>
      </Content>

      <Content className={clsCmn.sectionBack}>
        <h2 id="tutors">Преподаватели</h2>
        <Cards>
          {data.allMdx.nodes.map(node => (
            <Tutor
              key={node.slug}
              title={node.frontmatter.title}
              url={node.slug}
              imageData={
                node.frontmatter.embeddedImagesLocal 
                ? node.frontmatter.embeddedImagesLocal[0] 
                : undefined
              }
            >{node.frontmatter.description}</Tutor>
          ))}
        </Cards>
      </Content>

      <div id="contacts" className={cls.contacts}>
        <h2 id="courses">Контакты</h2>
        <div>
          <img src={classUrl} />
          <p>
            Добро пожаловать в нашу школу немецкого языка. Для успешного результата мы комбинируем онлайн занятия и реальные встречи. Наш подход уже оценили многие наши ученики, которые приходят к нам на занятия и продолжают своё обучение в немецких вузах. Если у вас есть вопросы, свяжитесь с нами любым удобным способом и мы Вам обязательно ответим.
          </p>
        </div>
      </div>


      {/* <Contacts title="Контакты" id="contacts">
      </Contacts> */}

    </Page>
  )
}


export const query = graphql`
query {
  allCourseCardsJson {
    nodes {
      url
      title
      subtitle
      content
    }
  }
  allMdx(
    filter: {
      fields: {sourceInstanceName: {eq: "content"}}, 
      slug: {glob: "tutors/*"},
      frontmatter: {visible: {ne: false}}
    }
    limit: 1000
    sort: {fields: frontmatter___order}
  ) {
    nodes {
      slug
      frontmatter {
        order
        visible
        type
        title
        tags
        subtitle
        description
        date
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}

`;