import * as React from "react";
import * as cn from 'classnames';
import * as cls from './styles.module.css';

export const Content = ({ 
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(cls.root, className)} {...props}>
      {children}
    </div>
  )
}