import * as React from "react";
import PropTypes from 'prop-types';
import * as cls from "./styles.module.css";
import * as cn from 'classnames';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Link } from "gatsby";

export const TutorCard = ({
  title,
  url,
  imageData,
  style,
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(cls.root, className)} style={style} {...props}>
      <Link to={url}>
        <GatsbyImage alt={title} className={cls.img} image={getImage(imageData)} />
        <h3 className={cls.title}>&gt; {title}</h3>
      </Link>
      <p className={cls.paragraph}>
        {children}
      </p>
    </div>
  )
}

TutorCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string
}