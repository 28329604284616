import * as React from "react";
import PropTypes from 'prop-types';
import * as cls from "./styles.module.css";
import * as cn from 'classnames';
import { Link } from "gatsby";

export const CourseCard = ({
  url,  
  title, 
    subtitle, 
    content, 
    className,
    ...props
}) => {
  return (
    <div className={cn(cls.root, className)} {...props}>
      <Link to={url} className={cls.title}><h2>{title}</h2></Link>
      { subtitle ? <h5 className={cls.subtitle}>{subtitle}</h5> : undefined }
      <p className={cls.content}>{content}</p>
      <Link to={url} className={cls.link}>&gt; Подробнее</Link>
    </div>
  )
}

CourseCard.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
}

